import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OrgManageService, ModifyPasswordService } from '@/sevices';
let ResetPassword = class ResetPassword extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.confirmLoading = false;
        this.loading = false;
        this.defaultPassword = '123456';
    }
    async showModal() {
        this.visible = true;
        this.loading = true;
        await this.getPasswordRule();
        this.loading = false;
    }
    async getPasswordRule() {
        const res = await ModifyPasswordService.getPasswordRuleInfo();
        if (res.success && res.data.passwordRuleStatus) {
            this.defaultPassword = res.data.defaultPassword;
        }
    }
    /**
     * 重置密码
     */
    resetUserPass() {
        this.confirmLoading = true;
        OrgManageService.resetUserPass({
            baseUserId: this.userDetail.baseUserId,
        }).then((res) => {
            if (res.success) {
                this.$message.success('重置成功');
                this.visible = false;
            }
        }).finally(() => {
            this.confirmLoading = false;
        });
    }
};
__decorate([
    Prop({
        default: () => {
            return {};
        },
    })
], ResetPassword.prototype, "userDetail", void 0);
ResetPassword = __decorate([
    Component({
        name: 'ResetPassword',
    })
], ResetPassword);
export default ResetPassword;
